import axios from 'axios';
import QS from 'qs';
import {ElMessage} from 'element-plus';
declare let ssoClient: any;

const service = axios.create({
    // 自定义的请求头
    headers: {
      post: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      'X-Requested-Width': 'XMLHttpRequest',
      token: null
    },
    // 超时设置
    timeout: 10*1000,
    // 跨域是否带token
    withCredentials: true,
    responseType: 'json',
    // httpAgent: new http.Agent({
    //   keepAlive: true
    // }),
    // httpsAgent: new https.Agent({
    //   keepAlive: true
    // })
  });
// 请求拦截器
service.interceptors.request.use(
    config => {
        if(config.method=="post"){
            config.data = QS.stringify(config.data);
        }
        // const token = getCookie('token');
        // const token = ssoClient.getCookie();
        // token && (config.headers.token = token);
        return config;
    },
    error => {
        return Promise.reject(error);
    })
// 返回状态判断
service.interceptors.response.use((res: any) => {
    let _data = null;
    if (res.code||res.data.code || res.data.code === 0 || res.data.data || res.data.data === 0) {
        switch (res.data.code) {
            case 1:
                // _data = res.data.data;
                _data = res.data;
                break;
            case -1:
                // console.error(`请求失败: ${res.config.url} ${JSON.stringify(res.config)}`);
                // _data = Promise.reject(res.data);
                _data = res.data;
                break;
            default:
                // _data = Promise.reject(res.data);
                _data = res.data;
                break;
        }
    } else {
        _data = res.data;
    }
    return Promise.resolve(_data);
}, (error) => {
  try {
      // 404等问题可以在这里处理
      switch (error.response.status) {
          case 400:
              error.message = '请求错误';
              break;

          case 401:
              error.message = '未授权，请登录';
              break;

          case 402:
              error.message = '登录过期，请重新登录';
              break;

          case 403:
              error.message = '拒绝访问';
              break;

          case 404:
              error.message = `请求地址出错: ${error.response.config.url}`;
              break;

          case 408:
              error.message = '请求超时';
              break;

          case 500:
              error.message = '服务器内部错误';
              break;

          case 501:
              error.message = '服务未实现';
              break;

          case 502:
              error.message = '网关错误';
              break;

          case 503:
              error.message = '服务不可用';
              break;

          case 504:
              error.message = '网关超时';
              break;

          case 505:
              error.message = 'HTTP版本不受支持';
              break;
          default:
      }
  } catch (err) {
        console.log(err);
  }
  ElMessage({message: error.message, type: 'error'})
  return Promise.reject(error);
});

export const POST = (url: string, params: object,baseUrl?:string) => {
    let _service: any = service;
    let _baseurl = baseUrl || process.env.VUE_APP_APIURL
    let serviceOptions: any = {
        method: 'post',
        url:_baseurl + url,
        data: params
    };

    return _service(serviceOptions);
};

export const GET = (url: string, params: object,baseUrl?:string) => {
    let _service: any = service;
    let _baseurl = baseUrl || process.env.VUE_APP_APIURL
    let serviceOptions: any = {
        method: 'get',
        url:_baseurl + url,
        params
    };

    return _service(serviceOptions);
};
