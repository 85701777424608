export interface IAppState {
    aliyunPath:string,
    headerMenuIndex: number
}

enum HEADER_MENU {
    HOME,
    TEACHAPP,
    OPENAPI,
    MUKUN
}

export default HEADER_MENU;