import HEADER_MENU, { IAppState } from '@/typings/app';
import { Module } from 'vuex';
import { IGlobalState } from '..';

const state:IAppState = {
    aliyunPath:'https://fs.iclass30.com',
    headerMenuIndex:HEADER_MENU.HOME
};

const app: Module<IAppState, IGlobalState> = {
    namespaced: true,
    state,
    mutations: {
    },
    actions: {
    }
};
export default app;