import { createStore } from 'vuex';
import app from './modules/app';
import user from './modules/user';
import { IAppState } from '@/typings/app';
import { IUserState } from '@/typings/user';

export interface IGlobalState {
  app: IAppState,
  user: IUserState
}

const store = createStore<IGlobalState>({
  mutations: {
  },
  actions: {
  },
  modules: {
    app,
    user
  }
});
export default store;
