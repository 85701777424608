import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '@/store'
import * as Types from '@/store/action-types';
import { ADMIN_TYPE, USER_TYPE } from '@/typings/user';
declare let ssoClient: any;

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        component: () => import(/* webpackChunkName: "about" */ '../views/main.vue'),
        redirect: "/home",
        children: [
            {
                path: "/home",
                name: "home",
                component: () => import(/* webpackChunkName: "about" */ '../views/home/index.vue'),
                meta: { keepAlive: true }

            },
            {
                path: "/teachapp",
                name: "teachapp",
                component: () => import(/* webpackChunkName: "about" */ '../views/teachApp/index.vue'),
                meta: { keepAlive: true }
            }
        ],

    },
    {
        path: "/checkLogin",
        name: "checkLogin",
        component: () => import(/* webpackChunkName: "about" */ '../views/checkLogin.vue'),
    },
    {
        path: "/wxlogin",
        name: "wxlogin",
        component: () => import(/* webpackChunkName: "about" */ '../views/thirdJoin/wxLogin.vue'),
    },
    {
        path: "/classroom",
        name: "classroom",
        component: () => import(/* webpackChunkName: "about" */ '../views/classroom/index.vue'),
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

// router.beforeEach((to, from, next) => {
//     ssoClient.check(process.env.VUE_APP_APPID, false);
//     const userInfo = ssoClient.getUserInfo();
//     const userId = userInfo.id;
//     if (userId) {
//         store.dispatch(`user/${Types.SET_USER_INFO}`,
//             { userId: userId }).then(res => {
//                 next();
//             }).catch(res => {
//                 next("/");
//             });
//     } else {
//         next();
//     }
// });


export default router
