/**
 * Created by sshu on 2021/03/15
 */
import * as API from '.';

// 获取用户信息
export const getLoginUserInfo = (params:object) => {
    return API.GET('/base/baselogin/getLoginUserInfo', params);
};
// 获取微信绑定用户
export const getWXUserInfoAPI = (params:object) => {
    return API.POST('/userMgr/loginoauth/getWXUserInfo',params,process.env.VUE_APP_BASEAPIURL)
}
// 绑定快捷登录账号
export const bindQuickLoginAPI = (params:object) => {
    return API.POST('/userMgr/loginoauth/bindQuickLogin',params,process.env.VUE_APP_BASEAPIURL)
}
// 用户登录
export const userLoginAPI = (params:object) => {
    return API.POST('/base/baselogin/login',params,process.env.VUE_APP_BASEAPIURL)
}









