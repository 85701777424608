<template>
  <div id='nav'>
    <router-view></router-view>
  </div>
</template>

<style lang="scss">
@import "./style/_layout.scss";
#nav {
  width: 100%;
  height: 100%;
}
</style>
