import { IUserState } from "@/typings/user";
import { Module } from 'vuex';
import { IGlobalState } from '..';
import * as Types from '../action-types';
import { getLoginUserInfo } from "@/service/api";

const state: IUserState = {
    token: '',
    userId: '',
    userName: '',
    realName: '',
    avatar: '',
    userType: '',
    adminType: '',
    schoolId: '',
    schoolName: '',
    subjectId: '',
    subjectName: ''
};

const user: Module<IUserState, IGlobalState> = {
    namespaced: true,
    state,
    mutations: {
        [Types.SET_USER_INFO](state, payload: any) {
            state.token = payload.token,
            state.userId = payload.id,
            state.userName = payload.user_name,
            state.realName = payload.realname,
            state.avatar = payload.avatar,
            state.userType = payload.user_type,
            state.adminType = payload.admin_type,
            state.schoolId = payload.schoolid,
            state.schoolName = payload.school_name,
            state.subjectId = payload.subjectid,
            state.subjectName = payload.sub_name_list
        }
    },
    actions: {
        async [Types.SET_USER_INFO]({ commit }, param) {
            return new Promise((resolve, reject) => {
                getLoginUserInfo(param)
                    .then(function (response: any) {
                        if (response && response.code > 0) {
                            if (response.code === 1) {
                                commit(Types.SET_USER_INFO, response.data);
                                resolve(response.data);
                            }
                        } else {
                            reject(response.msg);
                        }
                    })
                    .catch(function (error: any){
                        reject(error);
                    });
            });
        }
    }
};
export default user;