// import 'babel-polyfill'
import { createApp,h } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/style/base.css';
// 引入按需加载element-plus
import installElementPlus from './plugins/element-plus/ui-settings'
// 引入自定义样式
import './plugins/element-plus/element-variables.scss'

const app = createApp(App).use(store).use(router)
installElementPlus(app)
app.mount('#app')
